import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Nav from '../components/Nav/Nav'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" keywords={[`gatsby`, `application`, `react`]} />
    <Nav />

    <h3>Contact Us</h3>

    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLScCLGd9AWHCUsoAd1SbePTA81CUKLnXBAMKCGfAEVxmOcyTTQ/viewform?embedded=true"
      width="640"
      height="800"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="contact form"
    >
      Loading…
    </iframe>
  </Layout>
)

export default ContactPage
